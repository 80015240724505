import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
// import LoginPage from '../pages/login';

export default function CommonPage({ pageContext, data }) {
  const [user, setUser] = useState({});

  // const loginUser = () => {
  //   localStorage.setItem('user', JSON.stringify(true));
  //   window.location.reload();
  // };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };


  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    setUser(getUser);
  }, [user]);

  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={pageContext.appData.siteFooter}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
      >
      <h1>Temporaryly using common jsx</h1>
      </Layout>
    </>
  );
}

// export const query = graphql`
//   query pageTemplateData($contentful_id: String) {
//     allContentfulCustomVideo {
//       nodes {
//         contentful_id
//         entryTitle
//         videoComponent {
//           contentful_id
//           createdAt
//           entryTitle
//           videoId
//           videoType
//           videoTitle
//           dateOfPublishing
//         }
//       }
//     }
//     allContentfulPodcast {
//       nodes {
//         contentful_id
//         entryTitle
//         podcastDurationInMin
//         podcastEpisode
//         podcastSlug
//       }
//     }
//     allContentfulResearch {
//       nodes {
//         id
//         entryTitle
//         contentful_id
//         researchSlug
//         featuredInResourceHub
//         resourceType
//         researchTitle
//         thumbnailImage {
//           file {
//             url
//           }
//         }
//         shortDescription {
//           raw
//         }
//         sys {
//           contentType {
//             sys {
//               id
//             }
//           }
//         }
//       }
//     }
//     allContentfulCompVideoComponent {
//       nodes {
//         videoId
//         videoTitle
//         dateOfPublishing
//         videoType
//         entryTitle
//         sys {
//           contentType {
//             sys {
//               id
//             }
//           }
//         }
//         contentful_id
//       }
//     }
//     allContentfulBlogs {
//       nodes {
//         id
//         entryTitle
//         contentful_id
//         blogSlug
//         shortDescriptionBlog {
//           raw
//         }
//         featured
//         news
//         sys {
//           contentType {
//             sys {
//               id
//             }
//           }
//         }
//       }
//     }
//     contentfulPageTemplate(contentful_id: { eq: $contentful_id }) {
//       id
//       pageName
//       seo {
//         pageDescription
//         pageTitle
//         metaKeywords
//       }
//       entryTitle
//       contentful_id
//       components {
//         # ...ContentfulCompQumuCarousel
//         ...ContentfulCompRichTextBlock
//         ...ContentfulCompTextWithButton
//         # ...compBrandPartnerLogoStrip
//         # ...compNColumnLayout
//         ...container
//       }
//     }
//   }


//   fragment compPodcast on ContentfulPodcast {
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     id
//   }

//   fragment compResearch on ContentfulResearch {
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     entryTitle
//   }

//   fragment ContentfulCompImageWithText2 on ContentfulCompImageWithText2 {
//     id
//     titleText
//     titleUnderline
//     imageMaxHeight
//     lineHeight
//     fontSIze
//     backgroundColor
//     textColor
//     borderRadiusBottomLeft
//     borderRadiusBottomRight
//     borderRadiusTopLeft
//     borderRadiusTopRight
//     imageMaxWidth
//     buttonAlignExtraSmall
//     buttonAlignLarge
//     buttonAlignMedium
//     buttonAlignSmall
//     buttonAndTextPadding
//     widthUnderline
//     justifyButton
//     textBelowCtaButton {
//       raw
//     }
//     subText {
//       raw
//       references {
//         contentful_id
//         title
//         file {
//           url
//         }
//       }
//     }
//     image {
//       file {
//         url
//         contentType
//         fileName
//       }
//     }
//     isCtaButtonEnabled
//     button {
//       primaryCtaShouldOpenInTheSameTab
//       primaryCtaShouldBeDisplayedAs
//       displayPrimaryCta
//       labelForPrimaryCta
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment ContentfulCompTextWithButton on ContentfulCompTextWithButton {
//     id
//     entryTitle
//     title
//     button {
//       link {
//         label
//         link {
//           ... on ContentfulPageAction {
//             entryTitle
//           }
//         }
//       }
//     }
//     subText {
//       raw
//     }
//     backgroundColor
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment ContentfulCompRichTextBlock on ContentfulCompRichTextBlock {
//     id
//     richText {
//       raw
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment ContentfulCompImageOnly on ContentfulCompImageOnly {
//     id
//     image {
//       file {
//         url
//       }
//     }
//     maxHeight
//     maxWidth
//     imageName
//     lgBorderRadiusBottomLeft
//     lgBorderRadiusBottomRight
//     lgBorderRadiusTopLeft
//     lgBorderRadiusTopRight
//     mdBorderRadiusBottomLeft
//     mdBorderRadiusBottomRight
//     mdBorderRadiusTopLeft
//     mdBorderRadiusTopRight
//     smBorderRadiusBottomLeft
//     smBorderRadiusBottomRight
//     smBorderRadiusTopLeft
//     smBorderRadiusTopRight
//     width
//     xlBorderRadiusBottomLeft
//     xlBorderRadiusBottomRight
//     xlBorderRadiusTopLeft
//     xlBorderRadiusTopRight
//     xsBorderRadiusBottomLeft
//     xsBorderRadiusBottomRight
//     xsBorderRadiusTopLeft
//     xsBorderRadiusTopRight

//     borderBottomSize
//     borderBottomColor
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compImageWithFocalPoint on ContentfulImageWithFocalPoint {
//     id
//     image {
//       file {
//         url
//         details {
//           size
//           image {
//             height
//             width
//           }
//         }
//       }
//       gatsbyImageData
//       fluid {
//         srcSetWebp
//       }
//       description
//     }
//     focalPoint {
//       focalPoint {
//         x
//         y
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compAccordion on ContentfulCompAccordion {
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     id
//     accordionBody {
//       ... on ContentfulCompContainer {
//         rows {
//           columns {
//             component {
//               ...compRichTextBlock
//               ...compSubAccordion
//               ...compImageWithText2
//             }
//           }
//         }
//       }
//     }
//     accodionHeaderLabel
//   }

//   fragment compSubAccordion on ContentfulCompSubAccordion {
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     id
//     subAccordionHeaderLabel
//     subAccordionBody {
//       rows {
//         columns {
//           component {
//             ...compRichTextBlock
//             ...compImageWithText2
//           }
//         }
//       }
//     }
//   }

//   fragment compTabContainer on ContentfulCompTabContainer {
//     contentful_id
//     entryTitle
//     tabType
//     tabHeaderBackgroundColor
//     activeTabIndicatorColor
//     tabBodyBackgroundColor
//     tabBodyBoxShadow
//     tabItems {
//       contentful_id
//       entryTitle
//       tabLabel
//       tabBody {
//         ...compImageWithText2
//         ...compRichTextBlock
//         ...compAccordion
//         ...compTable
//         ...compImageOnly
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compFooterLinks on ContentfulFooterLinks {
//     entryTitle
//     links {
//       label
//       link {
//         ... on ContentfulWebLink {
//           id
//           url
//           openInANewTab
//         }
//         ... on ContentfulInternalLink {
//           slug
//           pageName
//           id
//           referenceToPage {
//             ... on ContentfulContactUs {
//               id
//               contentful_id
//             }
//             ... on ContentfulPageTemplate {
//               id
//               contentful_id
//             }
//           }
//         }
//       }
//     }
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compCardsContainer on ContentfulCompCardsContainer {
//     title
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     cards {
//       cardLabel
//       cardImage {
//         file {
//           url
//         }
//       }
//       cardContent {
//         link {
//           ... on ContentfulInternalLink {
//             slug
//             pageName
//             referenceToPage {
//               ... on ContentfulContactUs {
//                 id
//                 contentful_id
//               }
//               ... on ContentfulPageTemplate {
//                 id
//                 contentful_id
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   fragment compChipButtonList on ContentfulCompChipButtonList {
//     descriptionText {
//       raw
//     }
//     descriptionTextColor
//     descriptionTextFontSize
//     descriptionFontWeight
//     headingText {
//       raw
//     }
//     headingTextColor
//     headingFontWeight
//     headingTextFontSize
//     contentful_id
//     entryTitle
//     id
//     successMsg {
//       subText {
//         raw
//       }
//       button {
//         link {
//           label
//         }
//       }
//     }
//     components {
//       id
//       backgroundColor
//       contentful_id
//       entryTitle
//       backgroundImageOverlayColor
//       backgroundImageOverlayOpacity
//       backgroundImage {
//         file {
//           url
//         }
//       }
//       rows {
//         id
//         entryTitle
//         contentful_id
//         alignItems
//         justifyContent
//         alignText
//         spacing
//         topPadding
//         bottomPadding
//         leftPadding
//         rightPadding
//         backgroundColor
//         backgroundColorWidth
//         reverseColumn
//         columns {
//           id
//           entryTitle
//           contentful_id
//           backgroundColor
//           textColor
//           columnSize
//           columnSizeExtraSmallDevices
//           columnSizeMediumDevices
//           columnSizeSmallDevices
//           justifyContent
//           component {
//             ...ContentfulCompImageWithText2
//             ...ContentfulCompImageOnly
//             ...compImageWithFocalPoint
//             ...ContentfulCompRichTextBlock
//             ...compTabContainer
//             ...compFooterLinks
//             ...compCardsContainer
//             ...compTable
//             ...compMultipleCarousel
//             ...compFaqAccordion
//             ...compListContainer
//             ...compToggle
//             ...compVideoContainer
//             ...compTextOnly
//           }
//         }
//       }
//     }
//     chipButtons {
//       entryTitle
//       buttonText
//       buttonColor
//       hoverButtonTextColor
//       marginTop
//       marginRight
//       marginBottom
//       marginLeft
//       formType
//       chipButtonLink
//       iFrameLink
//       deliveryEmail
//       jsonFormQuery {
//         schema {
//           properties {
//             firstName {
//               title
//               type
//               pattern
//             }
//             lastName {
//               title
//               type
//               pattern
//             }
//             workEmail {
//               title
//               type
//               pattern
//             }
//             company {
//               title
//               type
//             }
//             jobTitle {
//               title
//               type
//               pattern
//             }
//             questionsOrComments {
//               title
//               type
//             }
//           }
//           type
//           required
//         }
//         uiSchema {
//           questionsOrComments {
//             ui_widget
//           }
//           workEmail {
//             ui_widget
//           }
//         }
//         errorSchema {
//           firstName {
//             message {
//               required
//               pattern
//             }
//           }
//           lastName {
//             message {
//               required
//               pattern
//             }
//           }
//           company {
//             message {
//               required
//               pattern
//             }
//           }
//           jobTitle {
//             message {
//               required
//               pattern
//             }
//           }
//           workEmail {
//             message {
//               required
//               pattern
//             }
//           }
//           questionsOrComments {
//             message {
//               required
//               pattern
//             }
//           }
//         }
//       }
//       description {
//         raw
//       }
//       heading {
//         raw
//       }
//       headingFontColor
//       headingFontSize
//       headingFontWeight
//       descriptionFontWeight
//       descriptionFontColor
//       descriptionFontSize
//       hasNestedButtons
//       nestedButtons {
//         descriptionFontWeight
//         descriptionTextColor
//         descriptionText {
//           raw
//         }
//         descriptionTextFontSize
//         headingFontWeight
//         entryTitle
//         headingText {
//           raw
//         }
//         headingTextColor
//         headingTextFontSize
//         chipButtons {
//           marginTop
//           marginRight
//           marginLeft
//           marginBottom
//           description {
//             raw
//           }
//           heading {
//             raw
//           }
//           descriptionFontColor
//           descriptionFontSize
//           descriptionFontWeight
//           entryTitle
//           deliveryEmail
//           buttonColor
//           buttonText
//           formType
//           iFrameLink
//           hoverButtonTextColor
//           headingFontWeight
//           headingFontSize
//           headingFontColor
//           jsonFormQuery {
//             errorSchema {
//               company {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//               firstName {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//               jobTitle {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//               lastName {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//               questionsOrComments {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//               workEmail {
//                 message {
//                   pattern
//                   required
//                 }
//               }
//             }
//             schema {
//               properties {
//                 firstName {
//                   title
//                   type
//                   pattern
//                 }
//                 lastName {
//                   title
//                   type
//                   pattern
//                 }
//                 workEmail {
//                   title
//                   type
//                   pattern
//                 }
//                 company {
//                   title
//                   type
//                 }
//                 jobTitle {
//                   title
//                   type
//                   pattern
//                 }
//                 questionsOrComments {
//                   title
//                   type
//                 }
//               }
//               type
//               required
//             }
//             uiSchema {
//               questionsOrComments {
//                 ui_widget
//               }
//               workEmail {
//                 ui_widget
//               }
//             }
//           }
//         }
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compTable on ContentfulCompTable {
//     id
//     entryTitle
//     description
//     cellPadding
//     fixedHeader
//     headerBackground
//     headerCellColor
//     rowHighlightColor
//     evenCellBackground
//     tableHeader
//     tableWithPagination
//     tableRows {
//       id
//       cellValues
//     }
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compMultipleCarousel on ContentfulCompMultipleCarousel {
//     id
//     entryTitle
//     selectCarouselType
//     frames {
//       image {
//         file {
//           url
//         }
//       }
//       contentAlignment
//       overlayAlignment
//       eyebrowText
//       heading
//       bodyText {
//         raw
//       }
//       primaryCallToAction {
//         displayPrimaryCta
//         labelForPrimaryCta
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compFaqAccordion on ContentfulCompFaqAccordion {
//     id
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     title
//     displayFaqTitleAsH1Tag
//   }

//   fragment compListContainer on ContentfulCompListContainer {
//     id
//     entryTitle
//     listItems {
//       id
//       listBody {
//         ...compImageOnly
//         ...compRichTextBlock
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compToggle on ContentfulCompToggle {
//     id
//     entryTitle
//     toggleButton1Label
//     toggleButton2Label
//     buyingHomeBody {
//       toggleBodyImage {
//         file {
//           url
//         }
//       }
//       toggleBodyText {
//         raw
//       }
//     }
//     sellingHomeBody {
//       toggleBodyImage {
//         file {
//           url
//         }
//       }
//       toggleBodyText {
//         raw
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compVideoContainer on ContentfulCompVideoEmbedded {
//     id
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     embeddedUrl {
//       id
//       embeddedUrl
//     }
//   }

//   fragment compTextWithImage on ContentfulCompTextWithImage {
//     id
//     alignText
//     bottomPadding
//     imageWidth
//     leftPadding
//     rightPadding
//     textColor
//     titleText
//     titleUnderline
//     topPadding
//     fontFamily
//     subText {
//       raw
//     }
//     image {
//       file {
//         url
//         contentType
//         fileName
//       }
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compPerson on ContentfulCompPerson {
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     emailId
//     entryTitle
//     profileTitle {
//       raw
//     }
//     bioWriteup {
//       raw
//     }
//     imageHeight
//     imageWidth
//     images {
//       file {
//         url
//       }
//     }
//     cardIs
//     colorText
//     shortDescription {
//       shortDescription
//     }
//     name
//     linkdln
//   }

//   fragment compTextOnly on ContentfulCompTextOnly {
//     entryTitle
//     headerText {
//       raw
//       references {
//         contentful_id
//         title
//         file {
//           url
//         }
//       }
//     }
//     containerPaddingTop
//     containerPaddingBottom
//     fontFamily
//     headingLineHeight
//     bottomPadding
//     colorText
//     anchorTagTextColor
//     anchorTagFontWeight
//     headingFontSize
//     headerSmallDevicesFontSize
//     headerExtraSmallDevicesFontSize
//     descriptionText {
//       raw
//       references {
//         contentful_id
//         title
//         file {
//           url
//         }
//       }
//     }
//     descriptionTextColor
//     descriptionLineHeight
//     descriptionFontSize
//     descriptionSmallDevicesFontSize
//     descriptionExtraSmallDevicesFontSize
//     textAlign
//     backgroundColor
//     containerPaddingTop
//     containerPaddingBottom
//     cssProperty {
//       ...responsivetheme
//     }
//     descriptionCssProperty {
//       ...responsivetheme
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compAttributeBlog on ContentfulCompAttributeBlog {
//     entryTitle
//     blogTitle
//     blogDescription
//     topPadding
//     leftPadding
//     dateAndTime
//     fontWeightForDate
//     richTextColor
//     podcastLogoBorderRadius
//     podcastLogo {
//       file {
//         fileName
//         url
//       }
//     }
//     cssForDate {
//       ...responsivetheme
//     }
//     cssForTitle {
//       ...responsivetheme
//     }
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compCategoryAndTags on ContentfulCompCategoryAndTags {
//     titles
//     title2
//     titleColor
//     linkColor
//     linkFontWeight
//     fontSize
//     textTransform
//     spacing
//     entryTitle
//     category {
//       raw
//     }
//     tags {
//       raw
//     }
//     cssProperty {
//       ...responsivetheme
//     }
//     sys {
//       contentType {
//         sys {
//           id
//           type
//         }
//       }
//     }
//   }

//   fragment compAuthor on ContentfulCompAuthor {
//     id
//     entryTitle
//     authorImage {
//       file {
//         url
//       }
//     }
//     authorName
//     authorDesignation
//     postedBy
//     authorDescription {
//       raw
//     }
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//   }

//   fragment compVideo on ContentfulCustomVideo {
//     sys {
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     entryTitle
//     videoComponent {
//       entryTitle
//       videoType
//       videoId
//       videoTitle
//       dateOfPublishing
//     }
//   }

//   fragment responsivetheme on ContentfulResponsiveTheme {
//     lgFontSize
//     lgMarginBottom
//     lgMarginLeft
//     lgMarginRight
//     lgMarginTop
//     lgPaddingBottom
//     lgPaddingLeft
//     lgPaddingRight
//     lgPaddingTop
//     mdFontSize
//     mdMarginBottom
//     mdMarginLeft
//     mdMarginRight
//     mdMarginTop
//     mdPaddingBottom
//     mdPaddingLeft
//     mdPaddingRight
//     mdPaddingTop
//     smFontSize
//     smMarginLeft
//     smMarginBottom
//     smMarginRight
//     smMarginTop
//     smPaddingBottom
//     smPaddingLeft
//     smPaddingRight
//     smPaddingTop
//     xlFontSize
//     xlMarginBottom
//     xlMarginLeft
//     xlMarginRight
//     xlMarginTop
//     xlPaddingBottom
//     xlPaddingLeft
//     xlPaddingRight
//     xlPaddingTop
//     xsFontSize
//     xsMarginBottom
//     xsMarginLeft
//     xsMarginRight
//     xsMarginTop
//     xsPaddingBottom
//     xsPaddingLeft
//     xsPaddingRight
//     xsPaddingTop
//   }

//   fragment innerContainer on ContentfulCompContainer {
//     id
//     backgroundColor
//     contentful_id
//     entryTitle
//     backgroundImageOverlayColor
//     backgroundImageOverlayOpacity
//     backgroundImage {
//       file {
//         url
//       }
//     }
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     rows {
//       id
//       entryTitle
//       contentful_id
//       alignItems
//       backgroundColor
//       backgroundColorWidth
//       reverseColumn
//       justifyContent
//       alignText
//       spacing
//       topPadding
//       bottomPadding
//       leftPadding
//       rightPadding
//       flexDirection
//       columns {
//         id
//         entryTitle
//         contentful_id
//         backgroundColor
//         textColor
//         columnSize
//         columnSizeLargeDevices
//         columnSizeExtraSmallDevices
//         columnSizeMediumDevices
//         columnSizeSmallDevices
//         justifyContent
//         component {
//           ...ContentfulCompImageWithText2
//           ...ContentfulCompImageOnly
//           ...compImageWithFocalPoint
//           ...ContentfulCompRichTextBlock
//           ...compTabContainer
//           ...compFooterLinks
//           ...compCardsContainer
//           ...compTable
//           ...compMultipleCarousel
//           ...compFaqAccordion
//           ...compListContainer
//           ...compToggle
//           ...compVideoContainer
//           ...compTextWithImage
//           ...compAuthor
//           ...compTextOnly
//           ...compChipButtonList
//           ...compPerson
//           ...compCategoryAndTags
//           ...compAttributeBlog
//         }
//       }
//     }
//   }
//   fragment container on ContentfulCompContainer {
//     id
//     backgroundColor
//     contentful_id
//     entryTitle
//     backgroundImageOverlayColor
//     backgroundImageOverlayOpacity
//     backgroundWidthSize
//     backgroundRepeat
//     position
//     backgroundHeightSize
//     backgroundImage {
//       file {
//         url
//       }
//     }
//     sys {
//       type
//       contentType {
//         sys {
//           id
//         }
//       }
//     }
//     rows {
//       id
//       entryTitle
//       contentful_id
//       alignItems
//       backgroundColor
//       backgroundColorWidth
//       reverseColumn
//       justifyContent
//       alignText
//       spacing
//       topPadding
//       bottomPadding
//       leftPadding
//       rightPadding
//       flexDirection
//       columns {
//         id
//         entryTitle
//         contentful_id
//         backgroundColor
//         textColor
//         columnSize
//         columnSizeLargeDevices
//         columnSizeExtraSmallDevices
//         columnSizeMediumDevices
//         columnSizeSmallDevices
//         justifyContent
//         component {
//           ...ContentfulCompImageWithText2
//           ...ContentfulCompImageOnly
//           ...compImageWithFocalPoint
//           ...ContentfulCompRichTextBlock
//           ...compTabContainer
//           ...compFooterLinks
//           ...compCardsContainer
//           ...compTable
//           ...compMultipleCarousel
//           ...compFaqAccordion
//           ...compListContainer
//           ...compToggle
//           ...compVideoContainer
//           ...compTextWithImage
//           ...compAuthor
//           ...compTextOnly
//           ...compChipButtonList
//           ...compPerson
//           ...compCategoryAndTags
//           ...compAttributeBlog
//           ...compImageWithButton
//           ...compResearch
//           ...innerContainer
//           ...compVideo
//         }
//       }
//     }
//   }
// `;
